$color_1: #1a1f36;
$color_2: #5469d4;
$color_3: #fff;
$color_4: #697386;
$font-family_1: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto,
  Helvetica Neue, Ubuntu, sans-serif;
$background-color_1: #ffffff;
$background-color_2: #5469d4;
$background-color_3: #212d63;
$background-color_4: #e3e8ee;
$background-color_5: #7fd3ed;
$background-color_6: rgb(255, 255, 255);
$background-color_7: rgb(84, 105, 212);
$outline-color_1: rgb(84 105 212 / 0.5);

@keyframes animationLeftRight {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(1000px);
  }
  100% {
    transform: translateX(0px);
  }
}
@keyframes animationRightLeft {
  0% {
    transform: translateX(0px);
  }
  50% {
    transform: translateX(-1000px);
  }
  100% {
    transform: translateX(0px);
  }
}

.login-root {
  background: #fff;
  display: flex;
  width: 100%;
  min-height: 100vh;
  overflow: hidden;
}
.loginbackground {
  min-height: 692px;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
  z-index: 0;
  overflow: hidden;
}
.flex-flex {
  display: flex;
}
.align-center {
  align-items: center;
}
.center-center {
  align-items: center;
  justify-content: center;
}
.box-root {
  box-sizing: border-box;
}
.flex-direction--column {
  -ms-flex-direction: column;
  flex-direction: column;
}
.loginbackground-gridContainer {
  display: -ms-grid;
  display: grid;
  -ms-grid-columns: [start] 1fr [left-gutter] (86.6px) [16] [left-gutter] 1fr
    [end];
  grid-template-columns: [start] 1fr [left-gutter] repeat(16, 86.6px) [left-gutter] 1fr [end];
  -ms-grid-rows: [top] 1fr [top-gutter] (64px) [8] [bottom-gutter] 1fr [bottom];
  grid-template-rows: [top] 1fr [top-gutter] repeat(8, 64px) [bottom-gutter] 1fr [bottom];
  justify-content: center;
  margin: 0 -2%;
  transform: rotate(-12deg) skew(-12deg);
}

.box-divider--light-all-2 {
  box-shadow: inset 0 0 0 2px #e3e8ee;
}
.box-background--blue {
  background-color: $background-color_2;
}
.box-background--white {
  background-color: $background-color_1;
}
.box-background--blue800 {
  background-color: $background-color_3;
}
.box-background--gray100 {
  background-color: $background-color_4;
}
.box-background--cyan200 {
  background-color: $background-color_5;
}
.padding-top--64 {
  padding-top: 64px;
}
.padding-top--24 {
  padding-top: 24px;
}
.padding-top--48 {
  padding-top: 48px;
}
.padding-bottom--24 {
  padding-bottom: 24px;
}
.padding-horizontal--48 {
  padding: 48px;
}
.padding-bottom--15 {
  padding-bottom: 15px;
}
.flex-justifyContent--center {
  -ms-flex-pack: center;
  justify-content: center;
}
.formbg {
  margin: 0px auto;
  width: 100%;
  max-width: 448px;
  background: white;
  border-radius: 4px;
  box-shadow: rgba(60, 66, 87, 0.12) 0px 7px 14px 0px,
    rgba(0, 0, 0, 0.12) 0px 3px 6px 0px;
}
// span {
// 	display: block;
// 	font-size: 20px;
// 	line-height: 28px;
// 	color: $color_1;
// }
// label {
// 	margin-bottom: 10px;
// 	font-size: 14px;
// 	font-weight: 600;
// 	display: block;
// }
.reset-pass {
  a {
    font-size: 14px;
    font-weight: 600;
    display: block;
  }
  > a {
    text-align: right;
    margin-bottom: 10px;
  }
}
.grid--50-50 {
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: center;
}
.field {
  input {
    font-size: 16px;
    line-height: 28px;
    padding: 8px 16px;
    width: 100%;
    min-height: 44px;
    border: unset;
    border-radius: 4px;
    outline-color: $outline-color_1;
    background-color: $background-color_6;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(60, 66, 87, 0.16) 0px 0px 0px 1px, rgba(0, 0, 0, 0) 0px 0px 0px 0px,
      rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px;
  }
}

.field-checkbox {
  input {
    width: 20px;
    height: 15px;
    margin-right: 5px;
    box-shadow: unset;
    min-height: unset;
  }
  label {
    display: flex;
    align-items: center;
    margin: 0;
  }
}

.animationRightLeft {
  animation: animationRightLeft 2s ease-in-out infinite;
}
.animationLeftRight {
  animation: animationLeftRight 2s ease-in-out infinite;
}
.tans3s {
  animation: animationLeftRight 3s ease-in-out infinite;
}
.tans4s {
  animation: animationLeftRight 4s ease-in-out infinite;
}

.splash-center-content {
  position: absolute;
  display: flex;
  justify-content: center;
  width: 40%;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 5000 !important;
  background: rgba(255, 255, 255, 0.8);
  backdrop-filter: saturate(180%) blur(10px);
  padding: 20px;
  border-radius: 30px;
}

.splash-center-content {
  background: rgba(255, 255, 255, 0.651);
}

@supports (-webkit-backdrop-filter: none) or (backdrop-filter: none) {
  .splash-center-content {
    -webkit-backdrop-filter: blur(10px);
    backdrop-filter: blur(10px);
  }
}

@media only screen and (max-width: 600px) {
    .splash-center-content{
        width: 90%;
    }
}
