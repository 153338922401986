.form-input {
  height: 50px !important;
  background-color: #f3f8fd !important;
  border: none !important;
  padding: 20px !important ;
  border-radius: 3px !important;

  &:focus {
    outline: none;
  }
}

.form-input-area {
  background-color: #f3f8fd !important;
  border: none !important;
  padding: 20px !important ;
  border-radius: 3px !important;

  &:focus {
    outline: none;
  }
}

.input-blue {
  height: 30px !important;
  // border: 1px solid #2f60d1 !important;
  background-color: #f3f8fd !important;
  padding: 15px !important;
}

.input-blue-search {
  padding-top: 10px !important;
  height: 35px !important;
}

.form-input::placeholder {
  color: #b8bcca;
  opacity: 1;
  font-size: 11px;
  font-weight: 300;
}

.form-input-area::placeholder {
  color: #b8bcca;
  opacity: 1;
  font-size: 11px;
  font-weight: 300;
}



.input-pan {
  position: relative;
}

.pan-success {
  position: absolute;
  right: 20px;
  left: auto;
  top: 30px;
}

.form-label {
  color: #6f7482 !important;
  font-size: 12.5px;
}
.form-label-black {
  color: #161616 !important;
  font-size: 12.5px;
}

.form-head {
  width: 100%;
  font-size: 15px;
  color: #c1c1c1 !important;
  padding-bottom:8px ;
  border-bottom: 1px solid #cfcfcf;
  margin-bottom: 15px;
}
.form-desc {
  font-size: 11px;
  color: #a6a6a7 !important;
}

.main-wrapper {
  padding: 20px;
}

.button-wrapper {
  padding: 0 20px;
}
.btn-submit {
  width: 100% !important;
  background-color: #274384 !important;
  height: 50px !important;
  box-shadow: none !important;
  border-radius: 10px !important;
  color: #fff !important;
}

.btn-submit-disabled {
  background-color: #a8b5d4 !important;
  height: 50px !important;
  box-shadow: none !important;
  color: #fff !important;
}

.btn-submit-white {
  color: #274384 !important;
  background-color: #fff !important;
  font-weight: 700 !important;
}

.btn-skip {
  width: 100% !important;
  background-color: #ffffff !important;
  height: 50px !important;
  box-shadow: none !important;
  border: 2px solid #274384 !important;
  border-radius: 10px !important;
  color: #274384 !important;
}

.verify-txt {
  font-size: 13px;
  color: #2f60d1;
}

.mand-star {
  color: red !important;
}

.error {
  width: 100%;
  color: red !important;
  font-size: 10px;
  margin-bottom: 0;
  display: block;
  word-wrap: break-word;
}

.capture-error {
  box-shadow: rgba(234, 14, 14, 0.258) 0px 8px 24px !important;
  border: 1px solid red;
}

.loading-screen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.aadhaar-wrapper {
  padding: 20px;
}

.content-center {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.Denied,
.Unsupported {
  background-color: rgb(213, 16, 16) !important;
  color: white !important;
  padding: 5px 15px;
  border-radius: 8px;
}

.Pending {
  background-color: rgb(213, 154, 16) !important;
  color: white !important;
  padding: 5px 15px;
  border-radius: 8px;
}

.Allowed {
  background-color: rgb(33, 170, 53) !important;
  color: white !important;
  padding: 5px 15px;
  border-radius: 8px;
}

.appbar-name {
  margin-bottom: 0px;
  color: gray;
  font-size: 16px;
}

.snackbar-button {
  color: white !important;
}

.paper-bottom {
  background-color: black !important;
  position: fixed !important;
  bottom: 0 !important;
  left: 0 !important;
  right: 0 !important;
  box-shadow: none !important;
}

.bottom-nav {
  display: flex !important;
  justify-content: center !important;
}

.terms-check-text {
  font-size: 12px;
  line-height: 18px !important;
  color: rgba(111, 116, 130, 1);
}

.multi-upload-btn {
  display: flex;
  justify-content: center;
  padding: 10px 0;
  border: 1px solid #eeeff0;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px !important;
}

.mini-heading {
  font-weight: 500;
  color: #2f60d1;
}

.hidden {
  display: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.safe-txt {
  color: #6f7482;
  font-size: 10px;
  text-align: center;
  margin-bottom: 10px;
}

.page-head {
  font-size: 22px;
  color: #274384;
  font-weight: 500;
}

.pointer {
  cursor: pointer !important;
}

.search-ico {
  position: absolute;
  right: 10px;
  top: 6px;
}

.chip-blue {
  cursor: pointer;
  width: 100% !important;
  border-color: #274384 !important;
  color: #274384 !important;
}

.chip-blue-fill {
  cursor: pointer;
  width: 100% !important;
  background-color: rgba(228, 236, 253, 1) !important;
  border-color: #274384 !important;
  color: #274384 !important;
}

.app-p {
  width: 100%;
  word-break: break-all;
  margin-bottom: 0 !important ;
}

// .app-p {
//   word-break: break-all;
//   margin-bottom: 0 !important ;
// }

.center{
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}


.doc-uploaded-success{
  cursor: pointer;
  font-size: 20px;
  margin-bottom: 20px;
  padding: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;


  .success-tick{
    color: green;
    margin-right: 10px;
    font-size: 30px;
  }
}

.loan-summary{
  position: relative;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  padding: 20px;
}

.loan-summary .head {
  margin-top: 20px !important;
  margin-bottom: 30px;
  font-size: 14px;
}

.loan-summary .key {
  font-size: 13px;
  color: #4d61fc;
}

.loan-summary .value {
  font-size: 13px;
  font-weight: 700;
}

.loan-summary .agree-img {
  width: 150px;
  right: 0;
  top: -30px;
  position: absolute;
}

.align-left {
  display: flex;
  justify-content: start !important;
}

.align-right {
  display: flex;
  justify-content: end !important;
}

.overlay-screen{
  position: absolute;
  width: 100% !important;
  height: 100%;
  background-color: rgb(255, 255, 255) !important;
  top: 0;
  z-index: 6000 !important;
  display: flex;
  justify-content:center ;
}

.multi-card-holder{
  padding-bottom: 100px !important;
}

.overflow-doc-list{
  width: 100%;
  height: 200px;
  overflow-y: scroll;
  overflow-x: hidden;

  &::-webkit-scrollbar {
    width: 5px;
  }
  
  /* Track */
  &::-webkit-scrollbar-track {
    background: #f1f1f1; 
  }
   
  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: #888; 
  }
  
  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: #555; 
  }

}

.mitc-table, th, td {
  border: 1px solid black;
  border-collapse: collapse;
}

.mitc-table td{
  padding: 6px;
}

.mitc-table td:nth-child(1){
  font-size: 12px;
}
.mitc-table td:nth-child(2){
  font-size: 12px;
  color: #274384;
}
