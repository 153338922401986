@import url("https://fonts.googleapis.com/css2?family=IBM+Plex+Sans:wght@500&family=Manrope:wght@200;300;400;500&display=swap");

.login-wrapper {
  display: flex;
  justify-content: center;
}

.mobile-text-wrapper {
  position: relative;
}

.login-mobile {
  height: 50px !important;
  border: none !important;
  padding: 20px !important ;
  border-radius: 3px !important;
  text-indent: 50px !important;
  font-size: 30px;
  font-weight: 400;
  &:focus {
    outline: none;
  }
}

.login-mobile::placeholder {
  color: #d4d6db !important;
  opacity: 1;
  font-size: 30px !important;
  font-weight: 400 !important;
}

// .password-eye{
//   position: absolute;
//   right: 15px;
//   top: 25px;
// }

.country-code {
  position: absolute;
  left: 15px;
  top: 3px;
  font-size: 30px !important;
  font-weight: 400 !important;
}

.login-info-txt {
  font-family: "IBM Plex Sans", sans-serif;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: 38px;
  line-height: 45px;
  margin-top: 20px;
}

.login-btn {
  width: 100% !important;
  background-color: #274384 !important;
  color: white !important;
  padding: 20px !important;
  border-radius: 30px !important;
  margin-top: 20px !important;
}

.strip {
  $color: #180044;
  width: 50%;
  height: 18px;
  color: $color;
  animation: zigzag 1s linear infinite;
  background: linear-gradient(
        135deg,
        rgba($color, 0.25) 0.25em,
        transparent 0.25em
      ) -0.5em 0,
    linear-gradient(225deg, rgba($color, 0.25) 0.25em, transparent 0.25em) -0.5em
      0,
    linear-gradient(315deg, rgba($color, 0.25) 0.25em, transparent 0.25em) 0 0,
    linear-gradient(45deg, rgba($color, 0.25) 0.25em, transparent 0.25em) 0 0;
  background-size: 0.75em 0.75em;
  color: adjust-hue($color, 180);
}

@keyframes zigzag {
  100% {
    background-position: 1em 0, 1em 0, -0.75em 0, -0.75em 0;
  }
}

.verification-div {
  max-width: 400px !important;
}

.verified-text {
  font-family: "IBM Plex Sans", sans-serif;
  font-family: "Manrope", sans-serif;
  font-weight: 500;
  font-size: 30px;
  color: rgb(33, 33, 33);
  margin-top: -30px;
  margin-bottom: 30px;
}

.otp-text {
  font-family: "IBM Plex Sans", sans-serif;
  font-family: "Manrope", sans-serif;
}

.verification_footer .info {
  font-family: "IBM Plex Sans", sans-serif;
  font-family: "Manrope", sans-serif;
}

@media only screen and (max-width: 600px) {
  .splash-center-content {
    width: 90%;
  }

  .login-icon-man {
    width: 80%;
  }

  .login-icon-holder {
    text-align: center;
    width: 100%;
  }
  .form-box {
    padding: 0 !important;
  }
}
