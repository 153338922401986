.kyc-dialog {
  position: fixed;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.275);
  backdrop-filter: blur(2px);
  z-index: 6000;
  top: 0;

  .welcome {
    margin-bottom: 0px;
    font-size: 20px;
    font-weight: 500;
    color: rgb(27, 92, 203);
  }

  .name {
    margin-bottom: 0px;
    font-size: 30px;
    font-weight: 500;
  }

  .dialog {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 90%;
    height: auto;
    background: white !important;
    border-radius: 10px;
    padding: 20px;
  }

  .steps-head {
    color: rgb(8, 1, 29);
    font-weight: 500;
  }
  .desc {
    color: gray;
    width: 90%;
    margin-top: 20px;
  }
  button {
    box-shadow: none !important;
    border-radius: 30px;
    padding: 10px 30px;
  }
}

.card {
  .card-content {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 30px 0;

    p {
      margin-bottom: 0;
      font-size: 20px;
    }
  }
}

.home-btn {
  width: 100%;
  height: 70px;
  text-transform: none !important;
  font-size: 18px !important;
  background-color: rgba(39, 67, 132, 1) !important;
  color: white !important;
  font-weight: 300 !important;
  border-radius: 20px !important;
}

.name-blue {
  font-size: 20px;
  color: rgb(27, 92, 203);
  font-weight: 500;
}

.home-card-new {
  margin-top: 90px;
  background: linear-gradient(110deg, #4b99ff 60%, #6dacff 60%);
  height: 190px;
  border-radius: 20px !important;
}

.sitting-img {
  margin-top: -110px;
  margin-left: 30px;
}

.apply-credit-text {
  font-family: "IBM Plex Sans", sans-serif;
  font-family: "Manrope", sans-serif;
  font-size: 35px;
  color: white;
}

.apply-now-btn {
  color: white !important;
  border: 2px solid white !important;
  border-radius: 20px !important;
}

@media only screen and (max-width: 600px) {
  .sitting-img {
    margin-top: -80px;
    margin-left: 5px;
    width: 200px;
  }
  .apply-credit-text {
    font-family: "IBM Plex Sans", sans-serif;
    font-family: "Manrope", sans-serif;
    font-size: 25px;
    color: white;
  }
}

.application-details-card{
  border-radius: 30px;
  overflow: hidden;
  box-shadow: rgba(10, 6, 116, 0.2) 0px 18px 50px -10px;

  .bottom-blue{
    padding: 20px;
    height: 60px;
    background: linear-gradient(110deg, #4b99ff 60%, #6dacff 60%);
  }
}