$breakpoint-tablet: 768px;
@media (min-width: $breakpoint-tablet) {
  .login-image {
    width: 300px;
  }

  .perm-instruction-img {
    width: 200px;
  }

  .permission-responsive {
    width: 50% !important;
    padding: 50px 30px !important;
  }

  .response-wrapper {
    width: 50% !important;
  }
  .kyc-wrapper {
    width: 50% !important;
  }

  .cheque-dialog {
    width: auto !important;
  }
  // .app-wrapper{
  //   display: flex;
  //   justify-content: center;
  // }
  .button-wrapper {
    width: 50% !important;
  }

  .tab-fixed {
    width: 50% !important;
  }

  .logout-btn {
    width: 50% !important;
  }

  .battery-holder {
    width: 45%;
  }
}

@media only screen and (max-width: 600px) {
  .info-text {
    width: 50%;
  }
}
