.profile-wrapper {
  .user-name {
    font-size: 20px;
    padding: 0 20px;
    margin-top: 30px;
  }

  .btn-logout {
    width: 100% !important;
    border: 1px solid red !important;
    color: red;
    border-radius: 30px !important;
  }
}

.profile-card {
  width: 100%;
  background-color: white;
  box-shadow: rgba(150, 150, 202, 0.1) 0px 4px 16px,
    rgba(25, 48, 122, 0.2) 0px 8px 32px;
  padding: 20px;

  .card-text {
    width: 100%;
    text-align: center;
    color: #274384;
    margin-bottom: 0;
  }

  .company {
    font-size: 20px;
    line-height: 23px;
    font-weight: 500;
  }
  .user {
    font-weight: 500;
  }

  .btn-view {
    background-color: #274384 !important;
    color: white !important;
    box-shadow: none !important;
    border: none !important;
    font-size: 12px !important;
    margin-top: 20px !important;
    padding: 10px 25px !important;
    text-transform: none !important;
  }
}

.card-button {
  height: 170px;
}

.logout-btn {
  width: 100% !important;
  position: absolute;
  bottom: 80px;
}

.profile-text {
  width: 100%;
  text-align: center;
  color: #274384 !important;
  margin-bottom: 0;
}

.company-name {
    font-size: 20px;
    font-weight: 500;
}

.user-name {
    font-size: 18px;
    // font-weight: 500;
}
