.wrapper {
  height: 100vh;
  text-align: center;

  .content {
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    transition: top 1s;

    h1 {
      font-size: 23px;
      white-space: nowrap;
      -webkit-transition: color 1s;
      transition: color 1s;
      color: white;
    }

    .desc {
      -webkit-transition: color 1s;
      transition: color 1s;
      color: rgba(255, 255, 255, 0.556);
    }
  }

  .login-wrapper {
    margin: 0;
    position: absolute;
    width: 100%;
    height: 100vh;
    transition: top 1s;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;
    padding-top: 0;
    transition: opacity 3s, padding 1s;
    z-index:1000;

    .form {
      width: 90%;
    }

    .head{
      text-align: left;
      font-size: 30px;
      font-weight: 500;
    }

    .desc{
      text-align: left;
      font-size: 15px;
      width: 90%;
      color: rgb(191, 191, 191);
      margin-bottom: 30px;
    }

    .form-input {
      width: 100%;
      height: 50px;
      border: none;
      background-color: rgb(244, 247, 255);
      margin-top: 10px;
      padding: 15px;
      box-sizing: border-box;

      &:focus{
        outline: none;
      }

    }

    .password-input{
      position: relative;
    }

    .login-mobile{
      text-indent: 30px;
    }

    // .password-eye{
    //   position: absolute;
    //   right: 15px;
    //   top: 25px;
    // }

    .password-eye{
      position: absolute;
      left: 15px;
      top: 23px;
    }

    .forgot-btn {
      width: 100%;
      display: flex;
      justify-content: right;
      color: rgb(68, 39, 202);
      font-size: 13px;
      margin-bottom: 25px;
      margin-top: 10px;
    }

    .login-button {
      width: 100%;
      height: 50px;
      box-shadow: none;
      border-radius: 20px;
    }

    .create-account-info {
      margin-top: 30px;
    }

    .bottom-toggle{
      color: rgba(89, 106, 255, 1);
    }
  }

  .embifi-loading {
    width: 100%;
    margin-top: 100px;
    transition: display 1s;

    .poweredby {
      color:rgba(255, 255, 255, 0.397);
      margin-bottom: 10px;

    }
  }
}

.js-circle {
  position: relative;
  overflow: hidden;
  z-index: 1;

  .circle {
    position: absolute;
    z-index: -1;
    border-radius: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation: circleGrowUp 1s ease-in forwards;

    &--mask {
      &:after {
        $gap: -100px;

        content: "";
        display: block;
        position: absolute;
        z-index: 1;
        top: $gap;
        left: $gap;
        right: $gap;
        bottom: $gap;
        background-color: rgba(255, 255, 255, 0.2);
        border-radius: 50%;
      }
    }
  }
}

@keyframes circleGrowUp {
  from {
    width: 0;
    height: 0;
  }

  to {
    width: 500vw;
    height: 500vw;
  }
}
