.loan-card {
  width: 100%;
  padding: 15px;
  box-shadow: rgba(150, 150, 202, 0.1) 0px 4px 16px,
    rgba(25, 48, 122, 0.2) 0px 8px 32px;

  .loan-amount {
    font-size: 25px;
    font-weight: 500;
    color: #6c63ff;
  }

  .loan-days {
    font-size: 22px;
    font-weight: 500;
    color: #274384;
  }
  .loan-percentage {
    font-size: 14px;
    font-weight: 500;
    color: #274384;
    margin-top: 15px;
  }
  .loan-details p {
    font-size: 12px;
    font-weight: 500;
    color: #3b4256;
    margin-right: 15px;
  }

  .active-button {
    background-color: #274384 !important;
    color: white !important;
  }
}

.loan-collapse {
  transition: opacity 0.2s ease-in;
  opacity: 1;
  height: 1;
}

.load-collapse-head {
  background-color: #4d6cb5;
  box-shadow: rgba(150, 150, 202, 0.1) 0px 4px 16px,
    rgba(25, 48, 122, 0.2) 0px 8px 32px;

  .col {
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: white;
    font-size: 10px;
  }
}

.load-collapse-row {
  box-shadow: rgba(150, 150, 202, 0.1) 0px 4px 16px,
    rgba(25, 48, 122, 0.2) 0px 8px 32px;
  margin-top: 15px;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  -o-user-select: none;
  user-select: none;

  .col {
    display: flex;
    justify-content: center;
    align-items: center;
    // padding-top: 10px;
    padding-bottom: 10px;
    font-size: 13px;
    font-weight: 500;
  }
}

.collapse-blue {
  border: 1px solid #274384 !important;
  margin-bottom: 15px !important;
  margin-top: 5px !important;
  border-radius: 10px !important;
  color: #274384 !important;
  box-shadow: none !important;
}

.collapse-shadow {
  margin-bottom: 15px !important;
  margin-top: 5px !important;
  border-radius: 10px !important;
  color: #274384 !important;
  padding: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px !important;
}
